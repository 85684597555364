import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

    var body = document.querySelector('body');
    var tag_list = document.querySelectorAll('.tag_list');
    var tag_loop = document.querySelector('.tag_loop');
    var tag_item = document.querySelectorAll('.tag_item');
    var cursor = document.querySelector('.cursor');
    var cursor_visual = document.querySelector('.cursor_visual');

    cursor.classList.add('size_large');

		tag_list.forEach(function(el) {
      var tag_looping = new TimelineMax({ repeat: -1 });
			tag_looping.staggerFromTo(el, 60, { xPercent: 0, ease: Linear.easeNone }, { xPercent: -100, ease: Linear.easeNone });
      tag_loop.addEventListener('mouseenter', function () {
        tag_looping.timeScale(.5);
        body.classList.add('hide_cursor');
      });
      tag_loop.addEventListener('mouseleave', function () {
        tag_looping.timeScale(1);
        body.classList.remove('hide_cursor');
      });
		})

		// tag_item.forEach(function(el) {
    //   var target_value = el.dataset.visual;

		// 	el.addEventListener("touchmove",  function(){
    //     cursor.classList.add('tag_loop_active');
    //     cursor_visual.style.backgroundImage = `url(${target_value})`;
    //   });
		// 	el.addEventListener("touchend",  function(){
    //     cursor.classList.remove('tag_loop_active');
		// 	});
      
		// 	el.addEventListener("mouseenter",  function(){
    //     cursor.classList.add('tag_loop_active');
    //     cursor_visual.style.backgroundImage = `url(${target_value})`;
		// 	});
		// 	el.addEventListener("mouseleave",  function(){
    //     cursor.classList.remove('tag_loop_active');
		// 	});
    // })

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術計劃命名活動</h2>
          <h1 className="main_title">LOGIN@_</h1>
        </div>


        <article className="page_content blog_single_post_content">
{/* 
          <section className="row_area_s">
            <div className="column_3">
              <figure className="figure">
                <img src={require("../../images/blog/post_3_3.jpg")} alt="張耿華"/>
              </figure>
            </div>
          </section> */}

          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <h4>登入台北 / 家入台北</h4>
                <h6>未來臺北藝術生活聚落/以人為本/台北居</h6>
                <h6>社宅是一個生命集合體</h6>
                <h6>一個有機體一個生態系Social Ecology & Biology Ecology</h6>
              </div>
            </div>
          </section>

          <section className="row_area_s tag_loop_row none_pointer">
            <div className="tag_loop">
              <div className="tag_wrap">
                <div className="tag_list">
                  <div className="tag_item">既舊且新的潮流城市</div>
                  <div className="tag_item">培育新世代市民的明日城市</div>
                  <div className="tag_item">愛學愛玩愛動手的創客城市</div>
                  <div className="tag_item">串聯山丘與河岸的綠動城市</div>
                  <div className="tag_item">友善高齡愛兒童的不老城市</div>
                  <div className="tag_item">共享共融多元的混居城市</div>
                </div>
              </div>
              <div className="tag_wrap">
                <div className="tag_list">
                  <div className="tag_item">共享共融多元的混居城市</div>
                  <div className="tag_item">友善高齡愛兒童的不老城市</div>
                  <div className="tag_item">串聯山丘與河岸的綠動城市</div>
                  <div className="tag_item">愛學愛玩愛動手的創客城市</div>
                  <div className="tag_item">培育新世代市民的明日城市</div>
                  <div className="tag_item">既舊且新的潮流城市</div>
                </div>
              </div>
            </div>
          </section>


          <section className="row_area_s">
            <div className="column_5 onset_left_1">
              <div className="context">
                <h4>Why LOGIN?</h4>
                <h6>Login，作為社宅+公共藝術，成為臺北藝術生活聚落之命名提案。</h6>
                <p>Login，是數位時代特有的動作，意味著我們將自己加入ㄧ個新的場域，加入ㄧ個異世界，加入ㄧ個新網絡，加入ㄧ種暫時的狀態；他可以意味著進住社宅的這三年，進入ㄧ個人生轉換的中繼站，可以意味著進入社宅的創作群，或者進入社宅的組織網絡，或就如字面字意，登入社宅公共藝術的訊息網站。以Login作為社宅公共藝術聚落名稱，希望邀集更多能量的加入，也有與不同世界之間轉換的意涵。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s">
            <div className="column_6 onset_left_1">
              <div className="context">
                <h4>各種LOGIN</h4>
                <p><strong>Login，也表現公共藝術作為各種轉接頭，媒合各種社會溝通與縫隙的積極性意義：</strong></p>
                <br/>
                <p><strong><b>Login @ 社宅基地 / 地域場所 ：「@」表示地點位置</b></strong></p>
                {/* <h6>例：</h6> */}
                <p><i>Login @ E01 ／ Login@EAST 南港機廠</i></p>
                <p><i>Login @ W01 / Login@West 福星</i></p>
                <p><i>Login @ N01 / Login@North 新奇岩</i></p>
                <p><i>Login @ S01 / Login@South 興隆一期</i></p>
                <br/>
                <p><strong><b>Login + 議題 / 藝術分類： 「+」表示再升級，或附加其他意涵</b></strong></p>
                {/* <h6>例：</h6> */}
                <p>Login + School </p>
                <p>Login + Garden</p>
                <p>Login + Playground</p>
                <p>Login + Kitchen</p>
                <br/>
                <p><strong><b>Login X 人物 / 活動：「X」代表Crossover跨界合作，不同領域間的交融。</b></strong></p>
                {/* <h6>例：</h6> */}
                <p>Login X Maker</p>
                <p>Login X Podcast  </p>
                <p>Login X 臺北廣播電台 </p>
                <p>Login X Youtuber </p>
                <p>Login X NGO團體</p>
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog" className="next_page">
            <div className="next_title">社群擾動</div>
            <div className="next_owner">對話</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
